import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));

export default [
  { path: '/', Component: Home },
  { path: '/login-admin', Component: LoginAdmin },
  { path: '*', Component: Home },
];
