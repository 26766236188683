import React from 'react';

const Dashboard = React.lazy(() => import('../pages/DashboardAdmin'));
const Admins = React.lazy(() => import('../pages/Admins'));
const Sliders = React.lazy(() => import('../pages/Sliders'));
const SessaoDinamicas = React.lazy(() => import('../pages/SessaoDinamicas'));

const routes = [
  {
    path: '/admin/',
    Component: Dashboard,
  },
  {
    path: '/admin/administradores',
    Component: Admins,
  },
  {
    path: '/admin/sliders',
    Component: Sliders,
  },
  {
    path: '/admin/sessoes-dinamicas',
    Component: SessaoDinamicas,
  },
];

export default routes;
