import React from 'react';
import { Spinner } from 'reactstrap';

function Loader({ loading }) {
  if (loading) {
    return (
      <div
        className="bg-primary opacity-7 d-flex align-items-center justify-content-center position-fixed left-0 top-0"
        style={{
          width: '100%',
          height: '130vh',
          zIndex: 999999,
        }}
      >
        <Spinner type="grow" />
      </div>
    );
  }

  return null;
}

export default Loader;
