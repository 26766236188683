import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import { ID_KEY_ADMIN, TOKEN_KEY_ADMIN, USER_NAME_ADMIN } from '../constants/auth';
import api from '../services/api';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [loggedUser, setLoggedUser] = useState({});
  const [userTokenAdmin, setUserTokenAdmin] = useState(null);
  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    // console.log(userTokenAdmin);
    if (!userTokenAdmin) {
      setIsAuthenticatedAdmin(false);
      return;
    }

    (async () => {
      try {
        const { data } = await api.get('/getAdmins');
        // console.log(data);
        if (data) {
          setIsAuthenticatedAdmin(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticatedAdmin(false);
        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();
  }, [userTokenAdmin]);

  const value = useMemo(
    () => ({
      isAuthenticatedAdmin,
      loggedUser,
      setLoggedUser,
      signInAdmin: (token, user) => {
        localStorage.setItem(TOKEN_KEY_ADMIN, token);
        localStorage.setItem(ID_KEY_ADMIN, user.id || user._id);
        localStorage.setItem(USER_NAME_ADMIN, user.name);
        setIsAuthenticatedAdmin(true);
        setUserTokenAdmin(token);
      },
      signOutAdmin: async () => {
        console.log('sign out');
        await api.delete('/logoutAdmins');
        localStorage.removeItem(TOKEN_KEY_ADMIN);
        localStorage.removeItem(ID_KEY_ADMIN);
        localStorage.removeItem(USER_NAME_ADMIN);
        setLoggedUser(null);
        setIsAuthenticatedAdmin(false);
        setUserTokenAdmin(null);
      },
    }),
    [userTokenAdmin, isAuthenticatedAdmin, loggedUser],
  );

  useEffect(() => {
    const tokenAdmin = localStorage.getItem(TOKEN_KEY_ADMIN);
    if (tokenAdmin) {
      setIsAuthenticatedAdmin(true);
      setUserTokenAdmin(tokenAdmin);
    }
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthProvider;
