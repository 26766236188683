/* eslint-disable no-param-reassign */
import axios from 'axios';

import { TOKEN_KEY_ADMIN, ID_KEY_ADMIN } from '../constants/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(TOKEN_KEY_ADMIN);
  const id = localStorage.getItem(ID_KEY_ADMIN);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.id = id;
  }
  return config;
});

export default api;
