import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

function Confirm(props, ref) {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({
    title: 'Mensagem',
    iconColor: 'primary',
    confirmColor: 'success',
    confirmText: 'Ok',
    cancelText: 'Cancelar',
    cancelColor: 'danger',
  });

  useImperativeHandle(ref, () => ({
    show: (data) => {
      // console.log(data);
      setModal({
        ...modal,
        ...data,
      });
      setShow(true);
    },
    close: () => setShow(false),
  }));

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <ModalHeader>
        <strong>{modal.title}</strong>
        <Button
          className="close position-absolute top-4 right-4 py-1 px-2"
          onClick={() => setShow(false)}
        >
          <i className="fas fa-times" />
        </Button>
      </ModalHeader>
      <ModalBody>{modal.message}</ModalBody>
      <ModalFooter>
        <Button color={modal.confirmColor} onClick={modal.onConfirm}>
          {modal.confirmText}
        </Button>
        {' '}
        <Button
          color={modal.cancelColor}
          onClick={modal.onCancel || (() => setShow(false))}
        >
          {modal.cancelText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default forwardRef(Confirm);
